.guess-item {
  cursor: default;
  flex: 0 0 calc(50% - 4px);
  font-size: 16px;
  border-radius: 0.6rem;
  color: rgba(0, 0, 0, 0.8);
  width: 100%;
  display: flex;
  gap: 8px;

  .guess-item-name {
    align-items: center;
    padding: 8px 12px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 1px 3px 0, rgba(0, 0, 0, 0.06) 0 1px 2px 0;
    width: 100%;
    -webkit-box-flex: 1;
    flex-grow: 1;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;

    &.bad {
      background-color: rgb(242, 211, 211);
    }

    &.collab {
      background-color: rgb(242, 239, 211);
      text-decoration: underline;
    }

    &.genre {
      background-color: rgb(242, 239, 211);
    }

    &.correct {
      background-color: rgb(223, 247, 223);
      font-weight: bold;
    }
  }

  .guess-item-flag {
    margin-left: auto;
    box-shadow: rgba(0, 0, 0, 0.1) 0 1px 3px 0, rgba(0, 0, 0, 0.06) 0 1px 2px 0;
    border-radius: 8px;
    white-space: nowrap;
    padding: 8px;

    &.bad {
      background-color: rgb(242, 211, 211);
    }

    &.collab {
      background-color: rgb(242, 239, 211);
    }

    &.correct {
      background-color: rgb(223, 247, 223);
    }
  }
}

