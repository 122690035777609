.button-container {
  display: flex;
  gap: 8px;
}

.alt-button {
  border-color: #f16078;
  background: #f16078;

  &:focus, &:hover {
    border-color: #ff8f9e;
    background: #ff8f9e;
  }

  &:active {
    color: #fff;
    border-color: #cc4762;
    background: #cc4762;
  }
}

h1 {
  &.result {
    font-weight: bold;
  }
}