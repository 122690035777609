@use "theme";

.container {
  padding: 1.5rem 16px;
  position: relative;
  background-color: theme.$primaryBgColor;
}

.header {
  background-color: theme.$primaryBgColor;
  margin: 0;
  display: flex;
  flex: 1;
  padding: 0.75rem 1.5rem;
  border-bottom: 1px solid #eaeaea;
  justify-content: space-between;
  align-items: center;

  span {
    font-size: 24px;
  }

  .language-switcher {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  @media (max-width: 700px) {
    span {
      font-size: 18px;
    }
  }

  h1 {
    margin-bottom: 0;
  }
}

.footer {
  background-color: theme.$primaryBgColor;
  display: flex;
  flex: 1;
  padding: 2rem 0;
  border-top: 1px solid #eaeaea;
  justify-content: center;
  align-items: center;

  a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

    &:hover {
      text-decoration: underline;
    }
  }
}

.main {
  min-height: 100vh;
  flex: 1 1 0;
  display: flex;
  flex-direction: column;

  @media (min-width: 700px) {
    align-items: center;
  }

  .game {
    flex-direction: column;
    display: flex;
    align-items: center;
    max-width: 650px;

    .genres-container {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      max-width: 650px;
    }

    .guessList-container {
      width: 100%;

      & > div {
        margin: 0.75rem 0 0 0;
      }
    }

    .search-container {
      margin-top: 2rem;
      width: 100%;

      @media (max-width: 700px) {
        margin-top: 0;
      }

      .search-hint {
        font-size: 12px;
        padding: 0.25rem 0.5rem;
        display: flex;
        width: 100%;
        justify-content: center;

        span.hint {
          font-weight: bold;
        }
      }

      & > div > div {
        border-radius: 0.6rem;

        & > div {

          &:first-child > div {
            display: flex;
            margin: 0 14px 0 0;
          }

          &:not(:first-child) {
            &:last-child {
              border-bottom-left-radius: 0.6rem;
              border-bottom-right-radius: 0.6rem;
            }
          }
        }
      }
    }

    .flags-container {
      margin-top: 0.75rem;
      font-size: 24px;

      .multiflags {
        font-size: 18px;
      }
    }
  }
}

.table_container {
  box-shadow: rgba(0, 0, 0, .1) 0 8px 20px -3px, rgba(0, 0, 0, .05) 0px 3px 6px -2px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width: 100%;

  @media (max-width: 700px) {
    border-radius: 0;
    margin: 0 -16px;

    width: calc(100% + 32px);
    margin-left: calc(-16px);
    margin-right: calc(-16px);
  }

  .clues {
    border-collapse: collapse;

    thead {
      tr {
        background-color: rgb(225, 227, 235);
        color: rgb(70, 73, 87);
        text-transform: uppercase;
        padding: 16px;

        th {
          padding: 16px;

          @media(max-width: 700px) {
            border-radius: 0;
          }

          @media(min-width: 700px) {
            &:first-child {
              border-top-left-radius: 8px;
            }

            &:last-child {
              border-top-right-radius: 8px;
            }
          }

        }
      }
    }

    tbody {
      tr {

        &:last-child {
          td {
            border-bottom: none;
          }
        }

        &.collab {
          background-color: rgb(242, 239, 211);
        }

        &.win {
          background-color: rgb(223, 247, 223);
        }

        &.clear {
        }


        td {
          padding: 16px;
          border-bottom: 2px solid rgb(238, 238, 238);
          background-color: inherit;

          .title_cell {
            font-size: 16px;
            font-weight: 500;
          }

          .text-gray {
            color: #808080;
          }
        }
      }
    }
  }
}

.search-result {
  cursor: pointer;
}

.modal-container {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
  gap: 32px;
  padding: 16px;

  h2 {
    text-align: center;
  }
}

.help-modal-container {
  text-align: justify;
  display: flex;
  -webkit-box-align: center;
  flex-direction: column;

  p {
    margin-bottom: 0.25rem;
  }

  .help-modal-examples {
    margin-top: 0.25rem;
    display: flex;
    gap: 8px;
    flex-direction: column;

    & > div {
      p {
        margin-bottom: 0.25rem;
      }

      margin-bottom: 0.5rem;
    }

    .match {
      font-weight: bold;
    }
  }
}


