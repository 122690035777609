.guess-results {
  display: flex;
  flex-direction: column;
  -webkit-box-align: stretch;
  align-items: stretch;
  justify-content: stretch;
  width: 100%;
  gap: 8px;
  min-height: 45px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(4, auto);

  @media (min-width: 700px) {
    flex-flow: wrap;
  }

  .guess-container {
    display: flex;
    flex: 0 0 calc(50% - 4px);
    font-size: 18px;
    border-radius: 8px;
    color: rgba(0, 0, 0, 0.8);
    width: 100%;
    gap: 8px;
  }
}

