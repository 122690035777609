.chip {
  user-select: none;
  cursor: default;
  border: none;
  border-radius: 16px;
  margin: 4px;
  padding: 4px 12px;
  text-transform: uppercase;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.533);

  @media (max-width: 700px) {
    padding: 4px 8px;
    font-size: 11px;
  }

  &.chip-artist {
    background-color: rgb(251, 248, 204);
  }

  &.chip-genre {
    background-color: rgb(253, 228, 207);
  }

  &.chip-genre-hidden {
    background-color: #e1e3eb
  }
}